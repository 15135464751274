
/**
 * Validate A List Of Email Addresses
 *
 * @param {String} str - single string of multiple email adresses
 * @return {String[]} An array of email addresses that are valid from the given argmuent
 */
export const validateEmailAddress = (str: string): string[] => {
  // const hasWhiteSpace = /\s/g.test(str);
  const output: string[] = [];
  // find how many emails are in the string
  const parsed = str.replace(/\s+/g, ' '); // Remove duplicate whitespace
  const arr = parsed.split(' '); // Split into array from whitespace denominator
  const words = arr ? arr.filter((e) => e !== '') : []; // Remove the blank items in the array
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  words.map((email: string) => {
    const isValid = re.test(String(email).toLowerCase());
    if (isValid) {
      output.push(email); // Add email to list
    }
  });

  return output;
};
