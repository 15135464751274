












































// @vuese
// Add and send emails

import {validateEmailAddress as emailCheck} from './utils/EmailParser';
import {SimplyVideoAdminLabel, distributorLabel} from '../router';

import {Store} from '@/store/store'
import {Component, Prop, Emit } from 'vue-property-decorator';

@Component
/**
 * 
 * This component renders an email input that adds emails to a list that is then submitted
 * 
 */
export default class AddEmails extends Store {
  componentTitle = 'Create New User';
  emails: Array<string> = [];
  userInput = '';
  
  @Prop({
    default() {
      return []
    }
  })knownEmails: any;

  simplyVideoAdminLabel = SimplyVideoAdminLabel;
  DistributorLabel = distributorLabel;

  async mounted() {
    this.refresh()
  }

  addEmailToList(email: string){
    this.validateEmailAddress(email)
  }

  async refresh(){
    this.emails = []
  }

  validateEmailAddress(email: string) {
    const emails = emailCheck(email);
    emails.map((email: string) => {
      if (!this.emails.find((element) => element == email) && !this.knownEmails.find((element: any) => element?.email == email) ) {
        if(this.isAdmin && !email.includes('simplyvideo.io')){
          
          this.$toast.error('Email Is Not of @simplyvideo.io domain. Rejecting Request')
        }
        else{
          this.emails.push(email); // Add email to list
          this.userInput = this.userInput.replace(email, ''); // Remove valid email from original string
        }
      } else {
        this.$toast.error(`${email} already exists`);
      }
    });
  }

  @Emit()
  sendActivationEmail(email: string[]) {
    this.refresh()
    return email
  }
}
