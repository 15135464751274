




















// @vuese
// @group Tables
// Invited Emails Table

import {Store} from '@/store/store'
import {Component, Prop, Emit } from 'vue-property-decorator';
import TableLoading from './TableLoading.vue'
/**
 * 
 * This component renders a list of accounts that the user can see, edit and delete
 * 
 */

interface Item {
  [key: string]: any;
  manage?: boolean;
  delete?: boolean;
  license?: boolean;
}

@Component({
  components:{
    TableLoading
  }
})
export default class InvitedEmailsTable extends Store {
  items: Item[] = []
  fields: Array<any> = [
    {key: 'email', label: 'Email', tdClass: 'email w33'},
    {key: 'invited_at', label: 'Invited on', tdClass: 'w33'},
    {key: 'is_pending', label: 'Status', tdClass: 'status'},
    {key: 'manage', label: '', tdClass: 'link w10'},
  ];

  @Prop({
    default() {
      return []
    }
  })knownEmails: any;

  @Emit()
  resendActivationEmail(email: string[]) {
    return email
  }

}
